
(function($) {

	$.preloadImages = function() {
		console.log(arguments[0]);
		for (var i = 0; i < arguments[0].length; i++) {
			$("<img />").attr("src", arguments[0][i]);
		}
	};

	function setHome() {
		if ($('body').hasClass('home')) {
			$('.hero-banner .slider-item').height($(window).height());
			$('.curtain').css('position','relative');
			$('.curtain').css('height','auto');
		bannerheight = $('.hero-banner').height();
		footer_height = $('.curtain').height();   
		$('.hero-banner').css('margin-bottom',footer_height + 'px');
		$('.curtain').css('position','fixed');
		$('.curtain').css('height',footer_height + 'px');
		}
	}
		
	function setArrows() {
		var left = $('.banner .container').offset().left + 15;
		var right = left + $('.banner .container').width() - $('.next-post').outerWidth() - 15;
		$('.prev-post').css('left',left);
		$('.next-post').css('left',right);
	}
	
	function setTeam() {
		$('#team .member-box').height($('#team .member-box').width());
	}

	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages
				var banner_images = [];
				$('.hero-banner .slider-item').each(function() {
					banner_images.push($(this).css('background-image').replace(/^url\(['"]?/,'').replace(/['"]?\)$/,''));
				});

				if(banner_images.length) {
					$.preloadImages(banner_images);
				}
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired

				setTeam();

				// Hero Banner (Owl Carousel)
				var heroBanner = $('#hero-banner');

				// Make Slider FullScreen
				$('.hero-banner .slider-item').height($(window).height());

				var $banner_overlay = $('<div class="banner-overlay"></div>');

				$('.hero-banner').append($banner_overlay);

				heroBanner.imagesLoaded({ background: '.slider-item' }).always(function() {
					$banner_overlay.fadeOut();
					console.log('images loaded');

					// Append elements to hero banner
					heroBanner.on('initialized.owl.carousel', function() {
						$('.hero-navs').append('<div class="arrow-down"></div>');
						$('#hero-banner .slider-item .overlay').css('opacity', 1);
					});

					// Enable OwlCarousel
					heroBanner.owlCarousel({
						loop: true,
						margin: 0,
						nav: true,
						items: 1,
						dots: true,
						autoplay: true,
						autoplayTimeout: 8500,
						autoplaySpeed: 500,
						dotsEach: false,
						dotsContainer: '.hero-dots .container',
						navContainer: '.hero-navs',
						navText: [' ',' '],
						// lazyLoad: true
					});

				});

				$('.burger-btn').click(function() {
					$(this).toggleClass('opened');
					$('.banner .brand').toggleClass('hide');
					$('#responsive-menu').slideToggle();
				});

				$(".light-gallery").lightGallery();

			}
		}
	};


	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);



	$(window).load(function() {

		$('.next-post,.prev-post').show();

		//Enable IsoTope on News listing
		$('#news').isotope({
			itemSelector: '.article-box',
			masonry: {
				columnWidth: '.article-box',
				gutter: '.gutter-sizer',
				percentPosition:true
			}
		});

		$('#project-gallery-mobile .isotopeItem a').fluidbox();    

		//Enable project gallery isotope
		$('#project-gallery-mobile').isotope({
			itemSelector:'.isotopeItem',
			layoutMode:'packery',
			packery: {
				gutter:'.gutter-sizer',
			}
		});

		//$('#project-gallery .item-wrap a').fluidbox();       

		$isotope_container = $('#project-isotope');

		$isotope_container.isotope({
			itemSelector:'.isotopeItem .project-box',
			masonry: {
				columnWidth:'.isotopeItem .project-box',
				gutter:'.gutter-sizer',
				percentPosition:true
			}
		});

	
		$('#project-filters ul li button').click(function() {
			if(!$(this).parent('li').hasClass('current')) {
				$(this).parent('li').addClass('current');
			var isoFilters = [];
			var elems = $('#project-filters ul').find('li.current');
			elems.each(function() {
				isoFilters.push($(this).children('button').attr('data-filter'));
			});
			var selector = isoFilters.join(',');
			$isotope_container.isotope({ filter: selector });
			}
		});
		
		$('#project-filters ul li .closed').click(function() {
			$(this).parent('li').removeClass('current');
			var isoFilters = [];
			var elems = $('#project-filters ul').find('li.current');
			elems.each(function() {
				isoFilters.push($(this).children('button').attr('data-filter'));
			});
			var selector = isoFilters.join(',');
			$isotope_container.isotope({ filter: selector });
		});



		$('#project-filters .responsive .filter-box ul li button').click(function() {
			$(this).parent('li').addClass('current');
			$('#project-filters .responsive .arrow').toggleClass('opened');
			//$('#project-filters .responsive .filter-box').slideToggle();
			var isoFilters = [];
			var elems = $('#project-filters ul').find('li.current');
			elems.each(function() {
				isoFilters.push($(this).children('button').attr('data-filter'));
			});
			var selector = isoFilters.join(',');
			$isotope_container.isotope({ filter: selector });
		});


		$('#project-filters .responsive .filter-box ul li .closed').click(function() {
		$(this).parent('li').removeClass('current');
			$('#project-filters .responsive .arrow').toggleClass('opened');
			//$('#project-filters .responsive .filter-box').slideToggle();
			var isoFilters = [];
			var elems = $('#project-filters ul').find('li.current');
			elems.each(function() {
				isoFilters.push($(this).children('button').attr('data-filter'));
			});
			var selector = isoFilters.join(',');
			$isotope_container.isotope({ filter: selector });
		});


		$('#project-filters .responsive .arrow').click(function() {
			$(this).toggleClass('opened');
			$('#project-filters .responsive .filter-box').slideToggle();
		});

		//Set footer on bottom if the main content smaller than the document size
		if(!$('body').hasClass('home')) {
			var docHeight = $(window).height();
			var footerHeight = $('.content-info').outerHeight();
			var footerTop = $('.content-info').position().top + footerHeight;
			if (footerTop < docHeight) {
				$('.content-info').css('margin-top',(docHeight - footerTop) + 'px');
			}
		}

		$('.content-info .widget').matchHeight();
		$('#related-projects .project-box').matchHeight();

		$('#project-outer .content-mobile').readmore({
			speed:500
		});

		var lastItems = $('#news .article-box');
		lastItems.slice(lastItems.length-2).addClass('last');

		setHome();
		setArrows();
	});
	
	$(window).resize(function() {
		setHome();
		setTeam();
		setArrows();
	});

	if( $('#project-outer').length ) {
		var innerHeight = $('.main').height();
		var windowHeight = $(window).height();
		var projTop = $('#project-outer').offset().top;
		// console.log($('#project-outer'));
		var mainTop = $('.main').offset().top;
		var arrowHeight = $('.prev-post').height();
		var middlePos = (windowHeight / 2);

		$(window).scroll(function(){
					
			offset = $(this).scrollTop(); 
			ePos = $('.prev-post').offset().top;
			if ( $('#related-projects').length > 0 ) {
				bottom = $('#related-projects').offset().top;
			} else {
				bottom = $('.content-info').offset().top;
			}
			// console.log(mainTop);
			// console.log(offset);
			// console.log(middlePos);
			// console.log(bottom);
			
			if ((mainTop-offset) < (middlePos-60)) {
				if (bottom-offset < 600) {
					$('.prev-post').removeClass('middle');
					$('.next-post').removeClass('middle');
					$('.prev-post').css('top',bottom-100);
					$('.next-post').css('top',bottom-100);

				} else {
					$('.prev-post').removeClass('bottom');
					$('.next-post').removeClass('bottom');
					$('.prev-post').addClass('middle').animate();
					$('.next-post').addClass('middle').animate();
				}
			} else {
				$('.prev-post').removeClass('middle').removeClass('bottom');
				$('.next-post').removeClass('middle').removeClass('bottom');
				$('.prev-post').css('top',projTop);
				$('.next-post').css('top',projTop);
			}
		});
	}

})(jQuery);
